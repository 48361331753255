import React, {useEffect, useState, useRef} from 'react';
import parser from 'html-react-parser'
import {Button} from "react-bootstrap";

import './index.scss';

const Intro = ({ content = "", company, handleChange, preview }) => {
    const [isEditable, setIsEditable] = useState(0);
    const introRef = useRef(null);
    let sanitizedContent = content.replace(/{{company}}/gm, company);
    sanitizedContent = parser(sanitizedContent);

    useEffect(() => {
        handleChange({
            intro: sanitizedContent
        });
    }, []);

    const _handleSave = () => {
        setIsEditable(0);
        handleChange({
            intro: introRef.current.innerHTML
        })
    }

    return (
        <>
            {
                !preview && <div className={"intro-actions"}>
                    { isEditable
                        ? <>
                            <small><super>*</super>Please draft/create the survey to avoid losing changes</small>
                            <Button variant={"primary"} color={"primary"} onClick={_handleSave}>Save</Button>
                        </>
                        : <div className='edit-btn' onClick={() => setIsEditable(1)}><i className={"fa-solid fa-pen-to-square"}></i> Edit</div>
                    }
                </div>
            }

            <div
                className="question intro-question"
                contentEditable={!!isEditable}
                ref={introRef}>
                {sanitizedContent}
            </div>
        </>
    );
};

export default Intro;
